/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  EmptyState,
  Accordion,
  CounterTag,
  MultiSelect,
  Spinner,
  Button,
} from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import {
  reopenPeriodModalVendorIds,
  vendorIds,
} from '../atoms/solicitation-helpers';
import useVendor from '../add-edit-solicitation/apis/vendor-apis';
import '../styles/select-standard.scss';

const AudienceList = (props) => {
  const { audienceList, isModal } = props;
  const [showClearButton, setShowClearButton] = useState(false);
  const [selectedVendorsTotalCount, setSelectedVendorsTotalCount] = useState(0);
  const [selectedAwardeesCount, setSelectedAwardeesCount] = useState(0);
  const [selectedActiveBidersCount, setSelectedActiveBidersCount] = useState(0);
  const [
    selectedCustomAudienceCount,
    setSelectedCustomAudienceCount,
  ] = useState(0);
  const [selections, setSelections] = useState([]);
  const [, setVendorIds] = useRecoilState(
    isModal ? reopenPeriodModalVendorIds : vendorIds,
  );
  const {
    vendors,
    activeBidders,
    contractAwardees,
    getContractAwardees,
    getActiveBidders,
    getVendors,
    isVendorDataLoading,
    isActiveDataLoading,
  } = useVendor();

  const { id } = useParams();

  const getVendorData = async () => {
    getVendors({
      variables: {
        filters: {
          operator: '$and',
          conditions: [
            {
              operator: '$in',
              key: 'vendorTypeCode',
              value: ['VehMfg', 'VehSupplier'],
            },
            {
              operator: '$exact',
              key: 'fleetStatus',
              value: 'Active',
            },
          ],
        },
        offset: 0,
        limit: 5000,
      },
    });

    if (id) {
      await Promise.all([
        getActiveBidders({
          variables: { solicitationId: +id },
        }),
        getContractAwardees({
          variables: { solicitationId: +id },
        }),
      ]);
    }
  };

  useEffect(() => {
    getVendorData();
  }, [id]);

  const audiences = [];

  // active bidders/ vendors participated on the previous period of a solicitation
  const activeBidderValues =
    activeBidders?.rows?.map((vendor) => ({
      value: vendor?.id,
      label: vendor?.vendorName,
    })) || [];

  // awardees/vendors with a contract associated with the previous solicitation period
  const contractAwardeeValues =
    contractAwardees?.rows?.map((vendor) => ({
      value: vendor?.vendorId,
      label: vendor?.vendorName,
    })) || [];

  // all vendors which are not active bidders
  const filteredCustomVendorValues =
    vendors?.rows
      ?.filter(
        (vendor) =>
          !activeBidders?.rows?.some((bidder) => bidder?.id === vendor?.id) &&
          !contractAwardees?.rows?.some(
            (awardee) => awardee?.vendorId === vendor?.id,
          ),
      )
      ?.map((vendor) => ({
        value: vendor?.id,
        label: vendor?.vendorName,
      })) || [];

  // remove vendors in contract awardees from active bidders
  const filteredActiveBidders = activeBidderValues?.filter(
    (activeBidder) =>
      !contractAwardeeValues?.some(
        (awardee) => awardee.vendorId === activeBidder.id,
      ),
  );

  audiences.push(
    {
      id: 1,
      title: 'Awardees',
      audienceType: 'Awardees',
      copyText:
        'All Vehicle Suppliers with a contract associated with this solicitation.',
      value: contractAwardeeValues,
    },
    {
      id: 2,
      title: 'Active bidders',
      audienceType: 'Bidders',
      copyText:
        'All Vehicle Suppliers who have bid on this solicitation in a prior open period this contact year.',
      value: filteredActiveBidders,
    },
    {
      id: 3,
      title: 'Custom audience',
      audienceType: 'Custom',
      copyText:
        'Ad Hoc selection of Vehicle Suppliers. You may also use this to add additional Vehicle Suppliers to an Awardees or Active bidders selection.',
      value: filteredCustomVendorValues,
    },
  );

  const groupAudienceByType = (ungroupedAudiences) => {
    const groupedAudiences = ungroupedAudiences?.reduce((x, y) => {
      // eslint-disable-next-line
      x[y.audienceType] = x[y.audienceType] || [];
      x[y.audienceType].push(y?.vendorId);
      return x;
    }, Object.create(null));
    setSelectedAwardeesCount(groupedAudiences?.Awardees?.length || 0);
    setSelectedCustomAudienceCount(groupedAudiences?.Custom?.length || 0);
    setSelectedActiveBidersCount(groupedAudiences?.Bidders?.length || 0);
  };

  useEffect(() => {
    if (audienceList) {
      const selectedVendorIds = audienceList?.map((item) => ({
        vendorId: item.vendorId,
        audienceType: item.audienceType,
      }));
      setSelections(selectedVendorIds);
      // eslint-disable-next-line
      selectedVendorIds?.length !== 0
        ? (setVendorIds(selectedVendorIds), setShowClearButton(true))
        : null;
    }
    groupAudienceByType(audienceList);
  }, [audienceList]);

  useEffect(() => {
    const totalVendorCount = selections.length;
    setSelectedVendorsTotalCount(totalVendorCount);
  }, [selections]);

  const handleVendorSelection = (selVehId, checked, type) => {
    let origSelections = [...selections];
    if (checked) {
      // eslint-disable-next-line
      showClearButton !== true ? setShowClearButton(true) : null;
      origSelections.push({ vendorId: selVehId, audienceType: type });
      // const currAudSelection = origSelections?.filter(
      //   (j) => j?.audienceType === 'Custom',
      // );
      // if (currAudSelection.length === values?.length) {
      //   setSelectAllChecked(true);
      // }
    } else {
      origSelections = origSelections.filter((i) => i?.vendorId !== selVehId);
      // eslint-disable-next-line
      origSelections.length === 0 ? setShowClearButton(false) : null;
      // setSelectAllChecked(false);
    }
    groupAudienceByType(origSelections);
    setSelections(origSelections);
    setVendorIds(origSelections);
  };

  const handleClearAll = () => {
    setVendorIds([]);
    setSelections([]);
    setSelectedCustomAudienceCount(0);
    setShowClearButton(false);
  };

  const setCounter = (currentAudience) => {
    switch (currentAudience?.audienceType) {
      case 'Awardees':
        return selectedAwardeesCount !== 0 ? (
          <CounterTag
            aria-label={selectedAwardeesCount}
            count={selectedAwardeesCount}
            className="margin-left-2 text-thin font-sans-xs text-center"
          />
        ) : null;
      case 'Bidders':
        return selectedActiveBidersCount !== 0 ? (
          <CounterTag
            aria-label={selectedActiveBidersCount}
            count={selectedActiveBidersCount}
            className="margin-left-2 text-thin font-sans-xs text-center"
          />
        ) : null;
      case 'Custom':
        return selectedCustomAudienceCount !== 0 ? (
          <CounterTag
            aria-label={selectedCustomAudienceCount}
            count={selectedCustomAudienceCount}
            className="margin-left-2 text-thin font-sans-xs text-center"
          />
        ) : null;
      default:
        return <></>;
    }
  };

  return (
    <div data-testid="audience-list-container" className="grid-col-12 ">
      {(isVendorDataLoading || isActiveDataLoading) && <Spinner />}
      <div
        className="grid-row flex-row padding-top-7 display-flex flex-justify-end"
        aria-label="audience selected"
      >
        <span
          className="text-bold font-sans-lg"
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          aria-label={selectedVendorsTotalCount}
        >
          {selectedVendorsTotalCount}
        </span>
        <span
          className="padding-left-1 font-sans-lg"
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          aria-label="Total vendors"
        >
          Total vendors
        </span>
      </div>

      <div className="grid-row flex-row ">
        <div className="padding-top-1">
          <span
            className="title-s-caps text-primary"
            aria-label="Choose Vendor"
            // eslint-disable-next-line
            tabIndex={0}
          >
            Choose Vendor
          </span>
        </div>
      </div>

      <div className="grid-col-auto">
        {!isVendorDataLoading &&
        !isActiveDataLoading &&
        audiences &&
        audiences?.length > 0
          ? audiences.map((audience, index) => {
              const { value, copyText, audienceType } = audience;
              const emptyStateMsg = `There are no ${
                audience?.title === 'Custom'
                  ? 'Custom audience'
                  : audience?.title
              }.`;
              const title = (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  {/* eslint-disable-next-line  */}
                  <span aria-label={audience?.title} tabIndex="0">
                    {audience?.title === 'Custom'
                      ? 'Custom audience'
                      : audience?.title}
                  </span>
                  {setCounter(audience)}
                </div>
              );
              const content = (
                <div className="grid-container audienceList">
                  <div className="grid-row">
                    <div className="tablet:grid-col">
                      <span
                        className="text-body text-base-dark font-sans-xs"
                        aria-label={copyText}
                        // eslint-disable-next-line
                        tabIndex={0}
                      >
                        {copyText}
                      </span>
                      {value?.length > 0 ? (
                        <>
                          {showClearButton ? (
                            <div className="padding-bottom-3 padding-top-3">
                              <Button
                                data-testid="clear-all-btn"
                                variant="outline"
                                onClick={() => handleClearAll()}
                                label="Clear all"
                              />
                            </div>
                          ) : null}

                          <MultiSelect
                            id="audienceLists"
                            key="audienceList"
                            data-testid={`solicitation-audience-multiselect-${index}`}
                            role="checkbox"
                            options={value?.map((v) => {
                              return {
                                label: v.label,
                                value: v.value,
                              };
                            })}
                            onChange={(v, checked) => {
                              handleVendorSelection(v, checked, audienceType);
                            }}
                            selectedValues={selections?.map(
                              (selectedId) => selectedId?.vendorId,
                            )}
                          />
                        </>
                      ) : (
                        <EmptyState
                          hasBackground={false}
                          topText={
                            <span
                              aria-label={emptyStateMsg}
                              // jsx-a11y/no-noninteractive-tabindex
                              tabIndex={0}
                            >
                              {emptyStateMsg}
                            </span>
                          }
                          containerStyles="padding-y-1"
                        />
                      )}
                    </div>
                  </div>
                </div>
              );

              return (
                <div className="grid-row flex-justify-self-start flex-align-baseline">
                  <Accordion
                    multiselectable
                    bordered
                    className="margin-top-1"
                    key={title}
                    items={[
                      {
                        title,
                        content,
                      },
                    ]}
                  />
                </div>
              );
            })
          : !isVendorDataLoading &&
            !isActiveDataLoading && (
              <EmptyState
                hasBackground={false}
                topText={
                  <span
                    aria-label="There are no vendors available."
                    // jsx-a11y/no-noninteractive-tabindex
                    tabIndex={0}
                  >
                    There are no vendors available.
                  </span>
                }
                containerStyles="padding-y-1"
              />
            )}
      </div>
    </div>
  );
};

AudienceList.propTypes = {
  audienceList: PropTypes.shape(Object).isRequired,
  isModal: PropTypes.bool,
};
AudienceList.defaultProps = {
  isModal: PropTypes.false,
};

export default AudienceList;
