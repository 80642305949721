import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  GET_VENDORS,
  GET_ACTIVE_BIDDERS,
  GET_CONTRACT_AWARDEES,
} from '../../queries';

export default function useVendor() {
  const [vendors, setVendors] = useState([]);
  const [activeBidders, setActiveBidders] = useState([]);
  const [contractAwardees, setContractAwardees] = useState([]);

  const [getVendors, { loading: isVendorDataLoading }] = useLazyQuery(
    GET_VENDORS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const sortedRows = [...data?.getFilteredVendors?.rows].sort((a, b) => {
          if (a.vendorName < b.vendorName) {
            return -1;
          }
          if (a.vendorName > b.vendorName) {
            return 1;
          }
          return 0;
        });
        setVendors({ rows: sortedRows });
      },
    },
  );

  // get active bidders/vendors
  const [getActiveBidders, { loading: isActiveDataLoading }] = useLazyQuery(
    GET_ACTIVE_BIDDERS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const sortedRows = [...data?.getActiveBidders].sort((a, b) => {
          if (a.vendorName < b.vendorName) {
            return -1;
          }
          if (a.vendorName > b.vendorName) {
            return 1;
          }
          return 0;
        });
        setActiveBidders({ rows: sortedRows });
      },
    },
  );

  // get contract awardees
  const [
    getContractAwardees,
    { loading: isAwardeesDataLoading },
  ] = useLazyQuery(GET_CONTRACT_AWARDEES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const sortedRows = [...data?.getContractsAwardees].sort((a, b) => {
        if (a.vendorName < b.vendorName) {
          return -1;
        }
        if (a.vendorName > b.vendorName) {
          return 1;
        }
        return 0;
      });
      setContractAwardees({ rows: sortedRows });
    },
  });

  return {
    vendors,
    getVendors,
    isVendorDataLoading,
    getActiveBidders,
    activeBidders,
    isActiveDataLoading,
    getContractAwardees,
    contractAwardees,
    isAwardeesDataLoading,
  };
}
