import React, { useCallback } from 'react';
import {
  RequiredFieldIndicator,
  FlexView,
  TextInput,
} from '@gsa/afp-component-library';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { useContractLine } from '../provider/contract-line-provider';
import LineMakeModelSection from './make-model/line-make-model';
import { enDashUnicode, emSpaceUnicode } from '../../../../utilities/constants';
import StartEndDateTimePicker from './start-end-date-time-picker';
import { ReadOnlyWrapper } from '../helpers/ec-components';

const validationSchema = yup.object().shape({
  quantity: yup
    .number()
    .transform((value) =>
      value === '' || Number.isNaN(value) ? undefined : value,
    )
    .moreThan(0, 'Allocation quantity must be 1 or more.'),
});

const LineSection = () => {
  const {
    contractLine,
    lineTabData,
    setLineTabData,
    canUpdateContract,
    canVendorUpdateContract,
  } = useContractLine();

  const canUpdateVehicleInfo = canUpdateContract || canVendorUpdateContract;

  const { control, register, errors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      quantity: lineTabData?.quantity || contractLine?.quantity,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const genLabel = (label, required) => (
    <span className="text-bold" data-testid={label}>
      {label} {required && <RequiredFieldIndicator />}
    </span>
  );

  const estimatedTotalQuantity =
    contractLine?.contractLineFinancialRef?.estimatedTotalQuantity;
  const estimatedFleetQuantity =
    contractLine?.contractLineFinancialRef?.estimatedFleetQuantity;

  const onFieldBlur = useCallback(
    (fieldName, event) => {
      const newValue = event.target.value;
      const currentValue =
        contractLine?.[fieldName] || lineTabData?.[fieldName] || '';
      if (String(newValue) === String(currentValue)) {
        return;
      }
      setLineTabData({
        ...lineTabData,
        [fieldName]: newValue,
      });
    },
    [lineTabData],
  );

  return (
    <>
      <LineMakeModelSection />
      <StartEndDateTimePicker />

      <div>
        <Controller
          name="quantity"
          control={control}
          render={({ value, onChange }) => {
            return (
              <div className="margin-top-4">
                <ReadOnlyWrapper>
                  <TextInput
                    name="quantity"
                    label={genLabel('Allocation', false)}
                    ref={register}
                    help="Enter a value if allocation is limited or leave blank for unlimited."
                    value={value === 0 ? emSpaceUnicode : value}
                    data-testid="quantity"
                    type="number"
                    min={0}
                    errorMessage={errors.quantity && errors.quantity.message}
                    onChange={onChange}
                    onBlur={(e) => {
                      if (!errors.quantity) {
                        onFieldBlur('quantity', e);
                      }
                    }}
                    disabled={!canUpdateVehicleInfo}
                  />
                </ReadOnlyWrapper>
              </div>
            );
          }}
        />
      </div>

      <FlexView className="margin-top-4">
        <div className="margin-right-8">
          <div className="text-bold">Estimated total quantity</div>
          <div>{estimatedTotalQuantity || enDashUnicode}</div>
        </div>
        <div>
          <div className="text-bold">Estimated fleet quantity</div>
          <div>{estimatedFleetQuantity || enDashUnicode}</div>
        </div>
      </FlexView>
    </>
  );
};

export default LineSection;
