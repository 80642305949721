import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SelectDropdown, Label } from '@gsa/afp-component-library';
import { DEFAULT_OPTION } from './form-helper';

const ContractPeriodsForm = ({ formValue, contractPeriods, onFormChange }) => {
  const [yearOptions, setYearOptions] = useState([DEFAULT_OPTION]);
  const [periodOptions1, setPeriodOptions1] = useState([DEFAULT_OPTION]);
  const [periodOptions2, setPeriodOptions2] = useState([DEFAULT_OPTION]);
  const fieldName = `report-contract-periods-select`;

  useEffect(() => {
    setYearOptions([
      DEFAULT_OPTION,
      ...contractPeriods
        .map((per) => {
          const year = String(per.contractYear);
          return { label: year, value: year };
        })
        .sort((opt1, opt2) => (opt1.label < opt2.label ? 1 : -1)),
    ]);
    setPeriodOptions1([DEFAULT_OPTION]);
    setPeriodOptions2([DEFAULT_OPTION]);
  }, [contractPeriods]);

  const onSelectYear = (yearNo, value) => {
    const options = [DEFAULT_OPTION];
    if (value) {
      const selectedYear = contractPeriods.find(
        (per) => per.contractYear === value,
      );
      options.push(...selectedYear.periodOptions);
    }
    if (yearNo === 1) setPeriodOptions1(options);
    else setPeriodOptions2(options);
    onFormChange({
      ...(yearNo === 1
        ? { year1: value, period1: '' }
        : { year2: value, period2: '' }),
      errorMessage: value ? '' : `Year ${yearNo} is required`,
    });
  };

  const onSelectPeriod = (yearNo, value) => {
    onFormChange({
      ...(yearNo === 1 ? { period1: value } : { period2: value }),
      errorMessage: value ? '' : `Period ${yearNo} is required`,
    });
  };

  return (
    <div
      data-testid={`${fieldName}-wrapper`}
      className={
        formValue.errorMessage
          ? 'report-contract-periods-form border-left-05 border-secondary-dark padding-left-2 margin-left-neg-2'
          : 'report-contract-periods-form'
      }
    >
      <Label className="usa-label text-bold margin-bottom-1" required>
        Select two contract periods for comparison
      </Label>
      {formValue.errorMessage && (
        <div className="text-bold text-secondary-dark margin-bottom-1">
          {formValue.errorMessage}
        </div>
      )}
      <div className="grid-row grid-gap">
        <div className="grid-row">
          <SelectDropdown
            id={`${fieldName}-year-1`}
            data-testid={`${fieldName}-year-1`}
            name={`${fieldName}-year-1`}
            label="Year 1"
            value={formValue.year1}
            options={yearOptions}
            onChange={({ target: { value } }) => onSelectYear(1, value)}
          />
          <div className="padding-right-1" />
          <SelectDropdown
            id={`${fieldName}-period-1`}
            data-testid={`${fieldName}-period-1`}
            name={`${fieldName}-period-1`}
            label="Period 1"
            value={formValue.period1}
            options={periodOptions1}
            onChange={({ target: { value } }) => onSelectPeriod(1, value)}
          />
        </div>
        <div className="grid-row">
          <SelectDropdown
            id={`${fieldName}-year-2`}
            data-testid={`${fieldName}-year-2`}
            name={`${fieldName}-year-2`}
            label="Year 2"
            value={formValue.year2}
            options={yearOptions}
            onChange={({ target: { value } }) => onSelectYear(2, value)}
          />
          <div className="padding-right-1" />
          <SelectDropdown
            id={`${fieldName}-period-2`}
            data-testid={`${fieldName}-period-2`}
            name={`${fieldName}-period-2`}
            label="Period 2"
            value={formValue.period2}
            options={periodOptions2}
            onChange={({ target: { value } }) => onSelectPeriod(2, value)}
          />
        </div>
      </div>
    </div>
  );
};

ContractPeriodsForm.propTypes = {
  formValue: PropTypes.shape(Object).isRequired,
  contractPeriods: PropTypes.arrayOf(Object).isRequired,
  onFormChange: PropTypes.func.isRequired,
};

export default ContractPeriodsForm;
