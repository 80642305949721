import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import {
  Modal,
  Button,
  DatePicker,
  TimePicker,
  SelectDropdown,
  ErrorMessage,
} from '@gsa/afp-component-library';
import {
  Controller,
  useFormContext,
  FormProvider,
  useForm,
} from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { UPDATE_CONTRACT_LINES } from './lines-query';
import updateLineItemsSchema from './update-line-item-schema';
import './update-contract-line.scss';
import ToastMessage from '../../../components/Toast/toast';

const UpdateLineItemsForm = ({ formData, onSubmit }) => {
  const { handleSubmit, control, setValue } = useFormContext();
  const [minDate, setMinDate] = useState(undefined);
  const [maxDate, setMaxDate] = useState(undefined);
  const getISODate = (dateStr) => {
    if (!dateStr) return undefined;
    return new Date(dateStr).toISOString().substring(0, 10);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-container">
        <Controller
          data-testid="open-date"
          name="openDate"
          control={control}
          defaultValue={formData?.openDate}
          render={({ value }) => (
            <DatePicker
              id="open-date"
              name="openDate"
              label="Open date"
              maxDate={maxDate}
              value={value}
              defaultValue={value}
              onChange={(v) => {
                setValue('openDate', v);
                setMinDate(getISODate(v));
              }}
              required
            />
          )}
        />

        <Controller
          data-testid="open-time"
          name="openTime"
          control={control}
          defaultValue={formData?.openTime}
          render={({ value }) => (
            <TimePicker
              id="open-time"
              name="openTime"
              label="Open time"
              value={value}
              onChange={(v) => setValue('openTime', v)}
              defaultValue={value}
              className="time-picker-container"
              required
            />
          )}
        />

        <Controller
          data-testid="close-date"
          name="closeDate"
          control={control}
          defaultValue={formData?.closeDate}
          render={({ value }) => (
            <DatePicker
              id="close-date"
              name="closeDate"
              label="Closeout date"
              minDate={minDate}
              value={value}
              defaultValue={value}
              onChange={(v) => {
                setValue('closeDate', v);
                setMaxDate(getISODate(v));
              }}
              required
            />
          )}
        />

        <Controller
          data-testid="close-time"
          name="closeTime"
          control={control}
          defaultValue={formData?.closeTime}
          render={({ value, ref }) => (
            <TimePicker
              id="close-time"
              name="closeTime"
              label="Closeout time"
              value={value}
              onChange={(v) => setValue('closeTime', v)}
              inputRef={ref}
              defaultValue={value}
              className="time-picker-container"
              required
            />
          )}
        />

        <Controller
          data-testid="isDirect"
          name="isDirect"
          control={control}
          defaultValue={formData?.isDirect}
          render={({ value }) => (
            <SelectDropdown
              id="isDirect"
              name="isDirect"
              label="Direct"
              options={[
                { value: '', label: '-' },
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
              ]}
              value={value}
              className="direct-container usa-select"
              onChange={(e) => {
                setValue('isDirect', e.target.value);
              }} // Handle state change
              required
            />
          )}
        />
      </div>
    </form>
  );
};

UpdateLineItemsForm.propTypes = {
  formData: PropTypes.shape({
    openTime: PropTypes.string,
    openDate: PropTypes.string,
    closeDate: PropTypes.string,
    closeTime: PropTypes.string,
    isDirect: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const UpdateLineItemsModal = ({
  onComplete,
  closeModal,
  disabled,
  headerData,
  lineItems,
}) => {
  const [toast, setToast] = useState(null);
  const [errors, setErrors] = useState([]);
  const { awardedDate, contractEndDate, contractTerminationDate } = headerData;
  const [updateContractLines] = useMutation(UPDATE_CONTRACT_LINES);

  const handleSubmit = async (formData) => {
    const { openDate, openTime, closeDate, closeTime, isDirect } = formData;
    setErrors([]);
    try {
      await updateLineItemsSchema(
        awardedDate,
        contractTerminationDate,
        contractEndDate,
      ).validate(formData, { abortEarly: false });
    } catch (error) {
      setErrors(error.errors);
      return;
    }

    const getDirectValue = (value) => {
      if (value === 'Yes') {
        return 1;
      }
      if (value === 'No') {
        return 0;
      }
      return null;
    };
    const updates = lineItems.map((item) => ({
      contractLineId: parseInt(item.id, 10), // Ensure contractLineId is an integer
      orderStartDate:
        openDate || openTime
          ? {
              date: openDate ? moment(openDate).format('YYYY-MM-DD') : null,
              time: openTime ? `${openTime}:00` : null, // Ensure time is in HH:mm:ss format
            }
          : null,
      orderEndDate:
        closeDate || closeTime
          ? {
              date: closeDate ? moment(closeDate).format('YYYY-MM-DD') : null,
              time: closeTime ? `${closeTime}:00` : null,
            }
          : null,

      isDirect: getDirectValue(isDirect),
    }));

    try {
      const { data } = await updateContractLines({
        variables: { contractLineUpdates: updates },
      });

      if (data) {
        onComplete(formData, lineItems, data);
        closeModal();
      }
    } catch (error) {
      setToast({
        type: 'error',
        message: `Failed to update contract lines: ${error.message}`,
      });
    }
  };

  const formData = {
    openDate: '',
    closeDate: '',
    openTime: '',
    closeTime: '',
    isDirect: null,
  };
  const defaultValues = {
    ...formData,
  };
  const methods = useForm({
    resolver: yupResolver(
      updateLineItemsSchema(
        awardedDate,
        contractTerminationDate,
        contractEndDate,
      ),
      { abortEarly: false },
    ),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues,
  });

  return (
    <FormProvider {...methods}>
      <Modal
        className="solicitation-modal"
        id="contract-header-maximum-value-modal"
        variant="extra-large"
        onClose={closeModal}
        actions={
          <>
            <Button
              type="button"
              variant="unstyled"
              onClick={closeModal}
              label="Cancel"
              disabled={disabled}
            />
            <Button
              data-testid="modal-save-changes-button"
              type="button"
              onClick={() => {
                methods?.handleSubmit(
                  handleSubmit(methods.getValues(), methods.errors),
                );
              }}
              label="Apply changes and close"
              disabled={disabled}
            />
          </>
        }
      >
        <div className="modal-content">
          <h2>Update selected lines</h2>
          {toast && (
            <ToastMessage
              type={toast.type}
              message={toast.message}
              onClose={() => setToast(null)}
              closable
            />
          )}
          <p>{lineItems.length} Contract lines selected</p>
          {errors &&
            errors.map((error) => (
              <ErrorMessage key={error}>{error}</ErrorMessage>
            ))}
          <UpdateLineItemsForm
            formData={formData}
            onSubmit={methods?.handleSubmit(handleSubmit)}
            headerData={headerData}
          />
        </div>
      </Modal>
    </FormProvider>
  );
};

UpdateLineItemsModal.propTypes = {
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      scheduleLine: PropTypes.string,
      standardItem: PropTypes.shape({
        vehicleGroup: PropTypes.shape({
          title: PropTypes.string,
        }),
        vehicleType: PropTypes.string,
        standardItemNumber: PropTypes.string,
        title: PropTypes.string,
      }),
      modelYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      orderStartDate: PropTypes.string,
      orderEndDate: PropTypes.string,
    }),
  ).isRequired,
  onComplete: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  headerData: PropTypes.shape({
    awardedDate: PropTypes.string,
    contractEndDate: PropTypes.string,
    contractTerminationDate: PropTypes.string,
  }).isRequired,
};
UpdateLineItemsModal.defaultProps = {
  disabled: false,
};

export default UpdateLineItemsModal;
