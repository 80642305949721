/* eslint-disable react/prop-types */
import React from 'react';
import { ErrorMessage } from '@gsa/afp-component-library';

const ReviewItemError = ({ error }) => {
  const noColorAddedError = error.lineTemplateErrors?.colorsTabErrors?.find(
    (e) => e.colorCode === '',
  );
  return (
    <>
      {error.lineTemplateErrors?.lineTabErrors?.length > 0 && (
        <>
          <div>Line tab errors:</div>
          <ul>
            {error.lineTemplateErrors.lineTabErrors.map((fieldError) => (
              <li>
                <ErrorMessage className="text-normal">
                  {fieldError.message}
                </ErrorMessage>
              </li>
            ))}
          </ul>
        </>
      )}
      {error.lineTemplateErrors?.lineDetailTabErrors?.length > 0 && (
        <>
          <div>Line detail tab errors:</div>
          <ul>
            {error.lineTemplateErrors.lineDetailTabErrors.map((fieldError) => (
              <li>
                <ErrorMessage className="text-normal">
                  {fieldError.message}
                </ErrorMessage>
              </li>
            ))}
          </ul>
        </>
      )}
      {error.lineTemplateErrors?.minReqTabErrors?.length > 0 && (
        <>
          <div>Minimum requirements tab errors:</div>
          <ul>
            <li>
              <ErrorMessage className="text-normal">
                There are {error.lineTemplateErrors?.minReqTabErrors?.length}{' '}
                incomplete EC inputs
              </ErrorMessage>
            </li>
          </ul>
        </>
      )}
      {error.lineTemplateErrors?.optEqTabErrors?.length > 0 && (
        <>
          <div>Optional equipment tab errors:</div>
          <ul>
            <li>
              <ErrorMessage className="text-normal">
                There are {error.lineTemplateErrors?.optEqTabErrors?.length}{' '}
                incomplete EC inputs{' '}
              </ErrorMessage>
            </li>
          </ul>
        </>
      )}
      {/* {error.lineTemplateErrors?.engineTabErrors?.length > 0 && (
        <>
          <div>Engine and fuel tab errors:</div>
          <ul>
            {error.lineTemplateErrors.engineTabErrors.map((e) => (
              <li>
                <ErrorMessage className="text-normal">{e.message}</ErrorMessage>
              </li>
            ))}
          </ul>
        </>
      )} */}
      {noColorAddedError && (
        <div>
          <div>Colors tab errors:</div>
          <ul>
            <li>
              <ErrorMessage className="text-normal">
                {noColorAddedError.errors[0].message}
              </ErrorMessage>
            </li>
          </ul>
        </div>
      )}
      {!noColorAddedError &&
        error.lineTemplateErrors?.colorsTabErrors?.length > 0 && (
          <>
            <div>Colors tab errors:</div>
            <ul>
              {error.lineTemplateErrors.colorsTabErrors.map((colorError) => (
                <li>
                  <div>{colorError.colorCode}</div>
                  <ul>
                    {colorError.errors.map((fieldError) => (
                      <li>
                        <ErrorMessage className="text-normal">
                          {fieldError.message}
                        </ErrorMessage>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </>
        )}
      {error.lineTemplateErrors?.documentsTabError && (
        <>
          <div>Documents tab error:</div>
          <ul>
            <li>
              <ErrorMessage className="text-normal">
                {error.lineTemplateErrors.documentsTabError}
              </ErrorMessage>
            </li>
          </ul>
        </>
      )}
    </>
  );
};

export default ReviewItemError;
