import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Spinner,
  Label,
  PageTitle,
  SelectDropdown,
  TextInput,
  Icon,
  connectModal,
  useModal,
  Link,
  NotFound,
  PhoneFaxInput,
} from '@gsa/afp-component-library';
import { Controller, useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { countriesPhoneCodesList } from './country-phone-codes';
import './contract-header.scss';

import {
  GET_CONTRACT_HEADER_BY_ID,
  GET_VENDOR_DETAIL_BY_ID,
  UNPUBLISH_CONTRACT_HEADER,
  UPDATE_CONTRACT_HEADER,
} from './contract-header.gql';
import ContractHeaderMaximumValueModal from './contract-header-maximum-value-modal';
import contractHeaderSchema from './contract-header-schema';
import ContractHeaderUnsavedModal from './contract-header-unsaved-modal';
import {
  emDashUnicode,
  OPERATIONS,
  SUBJECTS,
} from '../../../utilities/constants';
import ContractDetails from '../components/contract-details';
import ToastMessage from '../../../components/Toast/toast';
import { SOLICITATION_NUMBERS } from '../contract-constants';
import { ContractHeaderActions } from './contract-header-actions';
import { ContractHeaderBreadcrumbs } from './contract-header-breadcrumbs';

// TODO: Refactor contract-header-history-version.js to use this component
const StandardFieldset = ({ label, children, name, className = '' }) => {
  return (
    <fieldset className={`usa-fieldset usa-fieldset ${className}`} name={name}>
      {label && (
        <div className="text-primary text-bold text-uppercase border-bottom border-base-lighter padding-bottom-1">
          {label}
        </div>
      )}
      {children}
    </fieldset>
  );
};
StandardFieldset.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
StandardFieldset.defaultProps = {
  label: undefined,
  className: '',
};

const StandardFieldsetRow = ({ children, className = '' }) => {
  return (
    <div className={`grid-row margin-bottom-4 ${className}`}>{children}</div>
  );
};

StandardFieldsetRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

StandardFieldsetRow.defaultProps = {
  children: null,
  className: '',
};

const StandardFieldsetRowCol = ({ colWidth, children, label, data }) => {
  return (
    <div className={`col grid-col-${colWidth}`} aria-label={label}>
      {label && <Label>{label}</Label>}
      {data && <span>{data}</span>}
      {children}
    </div>
  );
};

StandardFieldsetRowCol.propTypes = {
  colWidth: PropTypes.number,
  children: PropTypes.node,
  label: PropTypes.string,
  data: PropTypes.node,
};

StandardFieldsetRowCol.defaultProps = {
  colWidth: 1,
  children: null,
  label: null,
  data: null,
};

const ReadOnlyWrapper = ({ children, readOnly, dataType }) => {
  const valuesOrEmDash = (...values) => {
    return values.find((value) => value) || emDashUnicode;
  };

  let extractedProps = {};
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      extractedProps = { extractedProps, ...child.props };
    }
  });

  const { defaultValue } = extractedProps;

  if (readOnly) {
    if (dataType === 'boolean') {
      return defaultValue ? 'Yes' : 'No';
    }

    if (dataType === 'phoneNumberWith_') {
      let countryCode = '';
      let phoneNumber = '';
      let extension = '';
      if (defaultValue?.includes?.('_')) {
        const [code, phone, ext] = defaultValue.split('_');
        countryCode = code === 'undefined' || !code ? '' : code;
        phoneNumber = phone || '';
        extension = ext || '';
      } else {
        phoneNumber = defaultValue || '';
      }
      return (
        <div className="display-flex flex-row">
          <span className="margin-right-3">
            <span className="usa-label text-bold ">Country code</span>
            {countryCode || emDashUnicode}
          </span>
          <span className="margin-right-3">
            <span className="usa-label text-bold ">Phone</span>
            {phoneNumber || emDashUnicode}
          </span>
          <span>
            <span className="usa-label text-bold ">Extension</span>
            {extension || emDashUnicode}
          </span>
        </div>
      );
    }
    return valuesOrEmDash(defaultValue);
  }

  return children;
};

const ContractHeader = () => {
  // const [contractHeader, setContractHeader] = useState({});
  const [isMaxDisabled, setIsMaxDisabled] = useState(false);
  const [isPublishedInFleetDisabled, setIsPublishedInFleetDisabled] = useState(
    false,
  );
  // const [getVendorDetailByIdData, setGetVendorDetailByIdData] = useState(null);
  const [alert, setAlert] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const history = useHistory();
  const { contractHeaderId } = useParams();
  const locationRef = useRef(null);
  const unblockRef = useRef(null);

  const {
    isOpen: maximumValueModalIsOpen,
    openModal: maximumValueModalOpenModal,
    closeModal: maximumValueModalCloseModal,
  } = useModal();
  const {
    isOpen: unsavedModalIsOpen,
    openModal: unsavedModalOpenModal,
    closeModal: unsavedModalCloseModal,
  } = useModal();

  const ability = useAppAbility();
  const canUpdateContract = ability.can(OPERATIONS.Update, SUBJECTS.Contract);

  const ConnectedContractHeaderMaximumValueModal = connectModal(
    ContractHeaderMaximumValueModal,
  );

  const ConnectedContractHeaderUnsavedModal = connectModal(
    ContractHeaderUnsavedModal,
  );

  const { handleSubmit, control, reset, errors, formState } = useForm({
    resolver: yupResolver(contractHeaderSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {},
  });

  const { data: contractHeaderData, loading } = useQuery(
    GET_CONTRACT_HEADER_BY_ID,
    {
      fetchPolicy: 'network-only',
      variables: {
        contractHeaderId,
      },
    },
  );

  useEffect(() => {
    const contractHeader = contractHeaderData?.getContractHeaderById || {};
    if (Object.keys(contractHeader).length === 0) {
      return;
    }

    setReadOnly(
      // NOTE: Disable medium headvy Legacy
      // https://cm-jira.usa.gov/browse/AFP-144880
      contractHeader?.solicitation?.solicitationNumber ===
        SOLICITATION_NUMBERS.MEDIUM_HEAVY,
    );

    reset({
      pocName: contractHeader.pocName,
      pocEmail: contractHeader.pocEmail,
      pocPhone: contractHeader.pocPhone,
      publishedInFleet: contractHeader.publishedInFleet,
      estimatedValue: contractHeader.estimatedValue,
    });

    setIsMaxDisabled(() => {
      if (contractHeader.estimatedValue === null) {
        return false;
      }

      if (contractHeader.estimatedValue === undefined) {
        return false;
      }

      return true;
    });
  }, [contractHeaderData]);

  const contractHeader = contractHeaderData?.getContractHeaderById || {};

  const { data: vendorDetailData, loading: loadingVendorDetailData } = useQuery(
    GET_VENDOR_DETAIL_BY_ID,
    {
      fetchPolicy: 'network-only',
      skip: !contractHeader.vendorId,
      variables: {
        id: contractHeader.vendorId,
      },
    },
  );

  const [updateContractHeader, { loading: updateLoading }] = useMutation(
    UPDATE_CONTRACT_HEADER,
    {
      refetchQueries: [
        {
          query: GET_CONTRACT_HEADER_BY_ID,
          variables: {
            contractHeaderId,
          },
        },
      ],
      variables: {
        contractHeaderId,
      },
      onCompleted: () => {
        setAlert({
          type: 'success',
          message: 'Contract header has been saved successfully.',
        });
      },
      onError: () => {
        setAlert({
          type: 'error',
          message: 'An error occurred while saving the contract header.',
        });
      },
    },
  );

  const [unpublishContractHeader] = useMutation(UNPUBLISH_CONTRACT_HEADER, {
    variables: {
      contractHeaderId,
    },
    onCompleted: () => {
      reset({ publishedInFleet: false });
    },
  });

  const onSubmit = (data) => {
    setAlert(null);

    const [code, phone] = data.pocPhone?.split('_') || [];
    if (phone && (code === undefined || !code)) {
      setAlert({
        type: 'error',
        message: 'Point of Contact Phone country code is required',
      });
      return;
    }

    if (
      !isMaxDisabled &&
      data.estimatedValue !== null &&
      !maximumValueModalIsOpen
    ) {
      maximumValueModalOpenModal();
      return;
    }

    updateContractHeader({
      variables: {
        contractHeaderUpdateInput: {
          pocName: data.pocName,
          pocEmail: data.pocEmail,
          pocPhone: data.pocPhone,
          publishedInFleet:
            data.publishedInFleet === true ||
            data.publishedInFleet === 'true' ||
            false,
          estimatedValue:
            data.estimatedValue !== null ? Number(data.estimatedValue) : null,
        },
      },
    });
  };

  const handleUnpublishContractHeader = ({ emailType }) => {
    if (contractHeader.publishedInFleet !== true) {
      return;
    }

    unpublishContractHeader({
      variables: {
        contractHeaderId,
        emailType,
      },
    });
  };

  const isEndDateInThePast = useMemo(() => {
    return (
      (contractHeader.contractEndDate &&
        moment().isAfter(contractHeader.contractEndDate, 'day')) ||
      false
    );
  }, [contractHeader.contractEndDate]);

  const isAwardCancelledDatePast = useMemo(() => {
    return (
      (contractHeader.awardCancelledDate &&
        moment().isAfter(contractHeader.awardCancelledDate, 'day')) ||
      false
    );
  }, [contractHeader.awardCancelledDate]);

  const isVendorExpired = useMemo(() => {
    return (
      (contractHeader.vendor?.registrationExpirationDate &&
        moment().isAfter(
          contractHeader.vendor.registrationExpirationDate,
          'day',
        )) ||
      false
    );
  }, [contractHeader.vendor?.registrationExpirationDate]);

  useEffect(() => {
    if (Object.keys(contractHeader).length === 0) return;

    if (isEndDateInThePast || isAwardCancelledDatePast) {
      setIsPublishedInFleetDisabled(true);
      handleUnpublishContractHeader({
        emailType: 'UNPUBLISH_CONTRACT_END_DATE_PAST',
      });
    }
    const isVendorFleetStatusInactive =
      contractHeader.vendor?.fleetStatus === 'Inactive';

    if (isVendorFleetStatusInactive) {
      setIsPublishedInFleetDisabled(true);
      handleUnpublishContractHeader({
        emailType: 'UNPUBLISH_VEHICLE_SUPPLIER_FLEET_STATUS_INACTIVE',
      });
    }

    if (isVendorExpired) {
      setIsPublishedInFleetDisabled(true);
      handleUnpublishContractHeader({
        emailType: 'UNPUBLISH_SAM_EXPIRATION_DATE_PAST',
      });
    }
  }, [
    isEndDateInThePast,
    isAwardCancelledDatePast,
    isVendorExpired,
    contractHeader,
  ]);

  useEffect(() => {
    const getVendorDetailByIdData = vendorDetailData?.getVendorDetailById;
    if (!getVendorDetailByIdData) {
      return;
    }

    const isVehicleSupplierValid =
      getVendorDetailByIdData?.vendorOrderTransmittals?.length > 0 &&
      getVendorDetailByIdData.vendorOrderTransmittals.some(
        (vendorOrderTransmittal) => {
          // If the order transmittal code is not E, then no validation is needex
          if (
            vendorOrderTransmittal.orderTransmittalCode &&
            vendorOrderTransmittal.orderTransmittalCode !== 'E'
          ) {
            return true;
          }

          // If the order transmittal code is E, check if email address is present
          if (
            vendorOrderTransmittal.orderTransmittalCode === 'E' &&
            vendorOrderTransmittal.emailAddr
          ) {
            return true;
          }

          // If the order transmittal code is not E and there is no email, return false
          return false;
        },
      );

    if (isVehicleSupplierValid) {
      return;
    }

    setIsPublishedInFleetDisabled(true);
    handleUnpublishContractHeader({
      emailType: 'UNPUBLISH_VEHICLE_SUPPLIER_TRANSMISSION_METHOD_INCOMPLETE',
    });
  }, [vendorDetailData]);

  // NOTE: ensures that users are warned about unsaved changes before navigating away
  // using in app navigation
  useEffect(() => {
    unblockRef.current = history.block((location) => {
      if (formState.isDirty) {
        locationRef.current = location;
        unsavedModalOpenModal();
        return false;
      }

      return true;
    });

    return () => {
      unblockRef.current();
    };
  }, [formState.isDirty, history]);

  if (loading || loadingVendorDetailData) {
    return (
      <div id="contract-header">
        <ContractHeaderBreadcrumbs />
        <Spinner data-testid="contract-header-spinner" size="small" />
      </div>
    );
  }

  if (Object.keys(contractHeader).length === 0) {
    return (
      <div id="contract-header">
        <ContractHeaderBreadcrumbs />
        <NotFound />
      </div>
    );
  }

  return (
    <div id="contract-header">
      <ContractHeaderBreadcrumbs />

      {alert && (
        <ToastMessage
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert(null)}
          closable
          className="margin-bottom-2"
        />
      )}

      <form id="contract-header-form" onSubmit={handleSubmit(onSubmit)}>
        <div
          className="view-solicitation"
          data-testid="view-solicitation-detail"
        >
          <div className="grid-row grid-gap margin-bottom-2">
            <div className="grid-col-10">
              <PageTitle
                title={
                  <>
                    Contract uPIID:{' '}
                    {contractHeader.contractUpiid ||
                      contractHeader.solicitation?.solicitationNumber ||
                      emDashUnicode}
                  </>
                }
              />
              <div>
                <span>Contractor: </span>
                <Link
                  href={`${window.AFP_CONFIG.appURLs.home}/vendor/details/${contractHeader.vendor?.id}`}
                >
                  {contractHeader.vendor?.vendorName}
                </Link>
              </div>
            </div>
            <div className="grid-col-2 display-flex flex-align-end flex-justify-end">
              <ContractHeaderActions />
            </div>
          </div>

          <ContractDetails contract={contractHeader} />

          <StandardFieldset
            name="vehicle-supplier-information"
            label="Vehicle Supplier Information"
          >
            <StandardFieldsetRow>
              <StandardFieldsetRowCol
                colWidth={3}
                label="Contractor UEI"
                data={contractHeader.vendor?.uniqueEntityIdentifier}
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="Fleet vendor number"
                data={
                  contractHeader.vendor?.fleetVendorNumber ||
                  contractHeader.vendor?.fleetVendorNo
                }
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="Fleet vendor status"
                data={contractHeader.vendor?.fleetStatus}
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="SAM registration expiration date"
                data={
                  contractHeader.vendor?.registrationExpirationDate
                    ? moment
                        .utc(contractHeader.vendor?.registrationExpirationDate)
                        .format('L')
                    : ''
                }
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="Point of contact"
                data={
                  <ReadOnlyWrapper readOnly={readOnly}>
                    <Controller
                      control={control}
                      name="pocName"
                      defaultValue={contractHeader.pocName || ''}
                      render={({ name, onChange, value }) => {
                        return (
                          <TextInput
                            name={name}
                            onChange={onChange}
                            value={value}
                            errorMessage={errors.pocName?.message}
                            disabled={!canUpdateContract}
                          />
                        );
                      }}
                    />
                  </ReadOnlyWrapper>
                }
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="Email"
                data={
                  <ReadOnlyWrapper readOnly={readOnly}>
                    <Controller
                      control={control}
                      name="pocEmail"
                      defaultValue={contractHeader.pocEmail || ''}
                      render={({ name, onChange, value }) => {
                        return (
                          <TextInput
                            name={name}
                            onChange={onChange}
                            value={value}
                            errorMessage={errors.pocEmail?.message}
                            disabled={!canUpdateContract}
                          />
                        );
                      }}
                    />
                  </ReadOnlyWrapper>
                }
              />
              <StandardFieldsetRowCol
                colWidth={6}
                data={
                  <ReadOnlyWrapper
                    readOnly={readOnly}
                    dataType="phoneNumberWith_"
                  >
                    <Controller
                      control={control}
                      name="pocPhone"
                      defaultValue={contractHeader.pocPhone}
                      render={({ onChange, value }) => {
                        let countryCode = '';
                        let phoneNumber = '';
                        let extension = '';
                        if (value?.includes?.('_')) {
                          const [code, phone, ext] = value.split('_');
                          countryCode =
                            code === 'undefined' || !code ? '' : code;
                          phoneNumber = phone || '';
                          extension = ext || '';
                        } else {
                          phoneNumber = value;
                        }

                        const handleCountryCodeChange = (newCode) => {
                          onChange(
                            `${newCode || ''}_${
                              phoneNumber || ''
                            }_${extension}`,
                          );
                        };

                        const handlePhoneNumberChange = (e) => {
                          onChange(
                            `${countryCode || ''}_${
                              e?.target?.value || ''
                            }_${extension}`,
                          );
                        };

                        const handleExtensionChange = (e) => {
                          onChange(
                            `${countryCode || ''}_${phoneNumber || ''}_${
                              e?.target?.value || ''
                            }`,
                          );
                        };

                        return (
                          <div className="phone-fax-wrap">
                            <PhoneFaxInput
                              countryCodeProps={{
                                options: countriesPhoneCodesList,
                                defaultValue: countryCode,
                                onChange: handleCountryCodeChange,
                                disabled: !canUpdateContract,
                                required: phoneNumber?.length > 0,
                              }}
                              phoneFaxProps={{
                                value: phoneNumber,
                                onChange: handlePhoneNumberChange,
                                disabled: !canUpdateContract,
                                errorMessage:
                                  countryCode === '+1' &&
                                  phoneNumber &&
                                  phoneNumber?.length !== 10
                                    ? 'Point of Contact Phone is not valid'
                                    : '',
                              }}
                              extensionProps={{
                                value: extension,
                                onChange: handleExtensionChange,
                                disabled: !canUpdateContract,
                              }}
                            />
                          </div>
                        );
                      }}
                    />
                  </ReadOnlyWrapper>
                }
              />
            </StandardFieldsetRow>
          </StandardFieldset>
          <StandardFieldset name="buyer-information" label="Buyer Information">
            <StandardFieldsetRow className="margin-bottom-8">
              <StandardFieldsetRowCol
                colWidth={3}
                label="Contracting officer"
                data={contractHeader.contractingOfficerName}
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="Contracting officer email"
                data={contractHeader.contractingOfficerEmail}
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="Contracting specialist"
                data={contractHeader.contractSpecialistName}
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="Contracting specialist email"
                data={contractHeader.contractSpecialistEmail}
              />
            </StandardFieldsetRow>
          </StandardFieldset>
          <StandardFieldset
            name="additional-contract-information"
            label="Additional Contract Information"
          >
            <StandardFieldsetRow className="margin-bottom-8 grid-gap-4">
              <StandardFieldsetRowCol
                colWidth={3}
                label="Start date" // Technically start-date is awarded date
                data={
                  contractHeader.awardedDate
                    ? moment(contractHeader.awardedDate).utc().format('L')
                    : ''
                }
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="End date"
                data={
                  contractHeader.contractEndDate
                    ? moment(contractHeader.contractEndDate).utc().format('L')
                    : ''
                }
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="Date last updated"
                data={
                  contractHeader.updatedAt
                    ? moment(contractHeader.updatedAt).format('L')
                    : ''
                }
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="Termination date"
                data={
                  contractHeader.awardCancelledDate
                    ? moment(contractHeader.awardCancelledDate)
                        .utc()
                        .format('L')
                    : '-'
                }
              />

              <StandardFieldsetRowCol
                colWidth={3}
                label="Contract maximum value"
                data={
                  <ReadOnlyWrapper readOnly={readOnly}>
                    <Controller
                      control={control}
                      name="estimatedValue"
                      defaultValue={contractHeader.estimatedValue ?? ''}
                      render={({ name, onChange, value }) => {
                        return (
                          <TextInput
                            containerClassName={isMaxDisabled ? 'disabled' : ''}
                            data-testid="contract-header-maximum-value"
                            name={name}
                            value={value ?? ''}
                            disabled={isMaxDisabled || !canUpdateContract}
                            onChange={onChange}
                            type="number"
                            prefix={<Icon iconName="attach_money" />}
                            errorMessage={errors.estimatedValue?.message}
                          />
                        );
                      }}
                    />
                  </ReadOnlyWrapper>
                }
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="Legacy solicitation"
                data={contractHeader.solicitation?.solicitationNumberLegacy}
              />
              <StandardFieldsetRowCol
                colWidth={2}
                label="Publish"
                data={
                  <ReadOnlyWrapper readOnly={readOnly} dataType="boolean">
                    <Controller
                      control={control}
                      name="publishedInFleet"
                      defaultValue={
                        contractHeader?.publishedInFleet === null ||
                        contractHeader?.publishedInFleet === undefined ||
                        contractHeader?.publishedInFleet === ''
                          ? ''
                          : contractHeader?.publishedInFleet
                      }
                      render={({ name, onChange, value }) => {
                        return (
                          <SelectDropdown
                            name={name}
                            onChange={onChange}
                            options={[
                              { label: 'Yes', value: true },
                              { label: 'No', value: false },
                            ]}
                            value={value}
                            disabled={
                              isPublishedInFleetDisabled || !canUpdateContract
                            }
                          />
                        );
                      }}
                    />
                  </ReadOnlyWrapper>
                }
              />
            </StandardFieldsetRow>
          </StandardFieldset>
          {canUpdateContract && !readOnly && (
            <StandardFieldset name="save">
              <StandardFieldsetRow>
                <Button
                  data-testid="contract-header-submit-button"
                  type="submit"
                  form="contract-header-form"
                  label="Save changes"
                />
              </StandardFieldsetRow>
            </StandardFieldset>
          )}
          <ConnectedContractHeaderMaximumValueModal
            isOpen={maximumValueModalIsOpen}
            closeModal={maximumValueModalCloseModal}
            disabled={updateLoading}
            onComplete={async () => {
              handleSubmit(onSubmit)();
              maximumValueModalCloseModal();
            }}
          />
          <ConnectedContractHeaderUnsavedModal
            isOpen={unsavedModalIsOpen}
            closeModal={unsavedModalCloseModal}
            disabled={updateLoading}
            leavePage={() => {
              unsavedModalCloseModal();
              unblockRef.current();
              history.push(locationRef.current.pathname);
            }}
            onComplete={async () => {
              handleSubmit(onSubmit)();
              unsavedModalCloseModal();
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default ContractHeader;
